import {client} from "./ApiClient";
import {mapServiceUrls} from "../Helpers/Globals";
import {useQuery} from "react-query";

const getCoupon = (key, urls, accessKey, code) => {
    return client(urls.LoyaltyEngineApiUrl, `api/v4/${accessKey}/coupons?couponCode=${code}`)
}

export default function useCoupon(urls, accessKey, code) {
    const {status, data, error, refetch } = useQuery(['Coupons', accessKey, code], (key) => getCoupon(key, mapServiceUrls(urls), accessKey, code), {enabled: !!accessKey && !!urls && !!code, staleTime: 1000*5*60})
    const coupon = data ? data.data : undefined
    return {status, coupon, error, refetch}
}