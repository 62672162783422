import { Settings } from '../Settings'
import PasswordInput from "../Components/Inputs/PasswordInput";
import EmailInput from "../Components/Inputs/EmailInput";
import SelectInput from "../Components/Inputs/SelectInput";
import TextInput from "../Components/Inputs/TextInput";
import {Fragment} from "react";
import queryString from "query-string";

export default class Globals {
    static addQueryParam = (url, name, value) => {
        if (value === undefined || value === null || value === '') {
            return url;
        }
        var fullUrl;
        if (url.includes("?")) {
            fullUrl = url + "&" + name + "=" + value;
        } else {
            fullUrl = url + "?" + name + "=" + value;
        }
        return fullUrl;
    }
}

const getCartParam = (url) => {
    const parsed = queryString.parse(url);
    const external = parsed.bl_cartexternalid
    const source = parsed.bl_cartsourceexternalid
    const uid = parsed.bl_cartuid
    if (external)
        return `cartexternalid=${external}`
    if (source)
        return `cartsourceexternalid=${source}`
    else
        return `cartuid=${uid}`
}

const mapUrlToOldSnippet = (url) => {
    console.log(url)
    const parsed = queryString.parse(url.toLowerCase());
    console.log(parsed)
    var newUrl = url.replace('new.bloyal', '.bloyal')
    newUrl = `${newUrl}?sessionKey=${parsed.bl_sk}&${getCartParam(url)}&logindomain=${parsed.bl_logindomain ?? ""}&cashiercode=${parsed.bl_cashiercode ?? ""}&ismanager=no`
    return newUrl
}

export const closeWindow = () => {
    const url = mapUrlToOldSnippet(window.location.href)
    console.log(url)
    var ww = window.open(url, '_self');
    ww?.close();
    window?.close()
}

export const onSnippetComplete = (returnUrl) => {
    if (!returnUrl) {
        closeWindow()
        return;
    }
    
    try {
        let url = returnUrl.includes('%') ? decodeURIComponent(returnUrl) : returnUrl
        window.location.href = decodeURIComponent(url)
    } catch (e) {
        console.log(e)
    }
}

String.prototype.isEmpty = function isEmpty() {
    return (!this || this.length === 0)
}

export const getQueryString = (url, key) => {
    var vars = [],
        hash;
    var hashes = (url.slice(url.indexOf('?') + 1).split('&'));

    for (var i = 0; i < hashes.length; i++) {
        hash = hashes[i].split('=');
        vars.push(hash[0].toLowerCase());
        vars[hash[0].toLowerCase()] = hash[1];
    }
    return vars[key.toLowerCase()];
}

export const hasCartIdentifier = (uid, externalId, sourceExternalId) => {
    return uid || externalId || sourceExternalId
}

export const checkBirthDate = (date) => {
    if (!date)
        return true
    
    const dateRegex = /^(?:(?:31(\/)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)\d{2})$/
    return dateRegex.test(date)
}

export const getDatePattern = () => {
    return "/(0\d{1}|1[0-2])\/([0-2]\d{1}|3[0-1])\/(19|20)\d{2}/"
}

export const getQueryParam = (name, value) => {
    if (!value)
        return ""
    
    return `&${name}=${value}`
}

export const checkValidDate = (date) => {
    if (!date)
        return true;
    // your desired pattern
    var pattern = /(\d{1}|0\d{1}|1[0-2])\/(\d|[0-2]\d{1}|3[0-1])\/(19|20)(\d{2})/
    var m = date.match(pattern);
    if (!m)
        return false;
    var d = new Date(date);
    // Now let's ensure that the date is not out of index


    if (d.getMonth()+1 == parseInt(m[1], 10) && d.getDate() ==parseInt(m[2], 10)) {
        return true;
    }
    return false;
}

export let languageCode = 'en-US'

export const updateLanguageCode = (code) => {
    languageCode = code
}

export const formatDate = (inputDate) => {
    if (inputDate === undefined || inputDate === null)
        return null

    let options = {year: "numeric", month: "2-digit", day: "2-digit"}
    if (inputDate.includes('T')) {
        let date = new Date(inputDate);
        // @ts-ignore
        return date.toLocaleDateString(languageCode, options);
    }

    var date = new Date(inputDate.replace(/-/g, '\/'));
    if (!isNaN(date.getTime())) {
        // @ts-ignore
        return date.toLocaleDateString(languageCode, options)
    }

    return inputDate.toLocaleString();
}

export const getFullDate = (date) => {
    var fullDate = date.includes('T') ? new Date(date) : new Date(date.replace(/-/g, '\/'))
    return fullDate
}

export const mapServiceUrls = (urls) => {
    if (urls === undefined || urls === null)
        return undefined;

    const definedUrls = { ...Settings.ServiceUrls };
    return {
        ...urls,
        ...definedUrls
    };
};

export const isNullOrUndefined = (value) => {
    return value === undefined || value === null;
    
    
}

export const getSessionLoginDomain = (window) => {
    try {
        if (window === undefined)
            return ''
        
        return window.sessionStorage.getItem('blLoginDomain')
    } catch (e) {
        console.log('Unable to access session storage.')
        return ''
    }
}

export const setSessionLoginDomain = (domain) => {
    try {
        sessionStorage.setItem('blLoginDomain', domain)
    } catch (e) {
        
    }
}

export const safeSetSessionStorage = (key, value) => {
    try {
        sessionStorage.setItem(key, value)
    } catch (e) {

    }
}

export const getDisplayValue = (text) => {
    if (text === null)
        return '';


    let displayString = '';
    let lastChar = '\0'
    Array.from(text).forEach((ch) => {
        if (displayString.length !== 0 && ((ch >= 'A') && (ch <= 'Z')) && (((lastChar < 'A') || (lastChar > 'Z')) && (lastChar != ' ')))
            displayString += ' ';

        displayString += ch;
        lastChar = ch;
    })

    return displayString;
}

export const areAnyLoading = (statuses) => {
    let found = false
    statuses.forEach(status => {
        if (status === 'loading')
            found = true
    })
    return found
}